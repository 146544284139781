import { data, useSearchParams } from '@remix-run/react'
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	type ColumnDef,
	type ColumnFiltersState,
	type PaginationState,
	type RowSelectionState,
	type SortingState,
	type Table as TanstackTable,
	type Updater,
	type VisibilityState,
} from '@tanstack/react-table'
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
	type ReactElement,
	type RefAttributes,
} from 'react'
import {
	PiArrowsVerticalFill,
	PiSortAscending,
	PiSortDescending,
} from 'react-icons/pi'
import {
	TbChevronLeft,
	TbChevronRight,
	TbChevronsLeft,
	TbChevronsRight,
} from 'react-icons/tb'
import { Button } from '#app/components/ui/button'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '#app/components/ui/table'
import { cn } from '#app/utils/misc'
import { id } from 'date-fns/locale'
import { BiCaretDown } from 'react-icons/bi'
import { string } from 'zod'
import { Badge } from './badge'
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
} from './dropdown-menu'

export function usePagination(pageSize: number) {
	const [searchParams, setSearchParams] = useSearchParams()
	const pageIndexParam = searchParams.get('page')
	const pageIndex = pageIndexParam ? Number(pageIndexParam) : 0

	const onPaginationChange = (
		updaterOrValue: Updater<{ pageIndex: number; pageSize: number }>,
	) => {
		const newPageIndex =
			typeof updaterOrValue === 'function'
				? updaterOrValue({ pageIndex, pageSize }).pageIndex
				: updaterOrValue.pageIndex

		const params = new URLSearchParams(searchParams)

		// Only set the page parameter if pageIndex is not 0
		if (newPageIndex === 0 && params.has('page')) {
			params.delete('page')
		} else if (newPageIndex !== 0) {
			params.set('page', String(newPageIndex))
		}

		setSearchParams(params)
	}

	return {
		onPaginationChange,
		pagination: {
			pageSize,
			pageIndex,
		} as PaginationState,
	}
}

type PaginationSettings = {
	disabled?: boolean
	pagesToShow?: number
	showFirstButton?: boolean
	showLastButton?: boolean
	showPreviousButton?: boolean
	showNextButton?: boolean
}
type PaginationProps<T> = {
	table: TanstackTable<T>
} & PaginationSettings

const PAGE_SIZE_OPTIONS = [25, 50, 100]

function Pagination<T>(props: PaginationProps<T>) {
	const {
		table,
		disabled = false,
		pagesToShow = 5,
		showFirstButton = true,
		showLastButton = true,
		showNextButton = true,
		showPreviousButton = true,
	} = props
	const { pageIndex } = table.getState().pagination
	const pageCount = table.getPageCount()

	// are we disabled?
	if (disabled) {
		return null
	}

	const halfPagesToShow = Math.floor(pagesToShow / 2)
	let startPage = Math.max(1, pageIndex + 1 - halfPagesToShow)
	let endPage = Math.min(pageCount, pageIndex + 1 + halfPagesToShow)

	// Adjust startPage and endPage if they are out of bounds
	if (endPage - startPage + 1 < pagesToShow) {
		if (startPage === 1) {
			endPage = Math.min(pageCount, startPage + pagesToShow - 1)
		} else if (endPage === pageCount) {
			startPage = Math.max(1, endPage - pagesToShow + 1)
		}
	}

	const pageNumbers = []
	for (let i = startPage; i <= endPage; i++) {
		pageNumbers.push(i)
	}

	return (
		<div className="flex items-center justify-end space-x-2">
			{showFirstButton && (
				<Button
					disabled={!table.getCanPreviousPage()}
					onClick={() => table.setPageIndex(0)}
					variant="outline"
					size="xs"
					className="hover:bg-white"
				>
					<TbChevronsLeft className="h-4 w-4" />
				</Button>
			)}
			{showPreviousButton && (
				<Button
					disabled={!table.getCanPreviousPage()}
					onClick={() => table.previousPage()}
					variant="outline"
					size="xs"
					className="hover:bg-white"
				>
					<TbChevronLeft className="h-4 w-4" />
				</Button>
			)}
			{pagesToShow > 0 &&
				pageNumbers.map((page) => (
					<Button
						key={page}
						onClick={() => table.setPageIndex(page - 1)}
						variant={pageIndex + 1 === page ? 'default' : 'outline'}
						size="xs"
						className={cn(
							pageIndex + 1 === page ? '' : 'hover:bg-white',
						)}
					>
						{page}
					</Button>
				))}
			{showNextButton && (
				<Button
					disabled={!table.getCanNextPage()}
					onClick={() => table.nextPage()}
					variant="outline"
					size="xs"
					className="hover:bg-white"
				>
					<TbChevronRight className="h-4 w-4" />
				</Button>
			)}
			{showLastButton && (
				<Button
					disabled={!table.getCanNextPage()}
					onClick={() => table.setPageIndex(pageCount - 1)}
					variant="outline"
					size="xs"
					className="hover:bg-white"
				>
					<TbChevronsRight className="h-4 w-4" />
				</Button>
			)}
		</div>
	)
}

type useSortingProps = {
	initialField?: string
	initialOrder?: 'asc' | 'desc'
}

export function useSorting(props?: useSortingProps) {
	// destructure the props
	const { initialField = 'created_at', initialOrder = 'asc' } = props ?? {}

	const [searchParams, setSearchParams] = useSearchParams()
	const [sorting, setSorting] = useState<SortingState>([
		{ id: initialField, desc: initialOrder === 'desc' },
	])

	const onSortingChange = (updaterOrValue: Updater<SortingState>) => {
		const params = new URLSearchParams(searchParams)
		let newSorting: SortingState = []

		if (typeof updaterOrValue === 'function') {
			newSorting = updaterOrValue(sorting)
		} else if (Array.isArray(updaterOrValue)) {
			newSorting = updaterOrValue
		} else {
			return
		}

		const newSortByField = newSorting?.[0]?.id
		const newSortByOrder = newSorting?.[0]?.desc

		if (
			(!newSortByField || newSortByOrder === undefined) &&
			params.has('sort')
		) {
			params.delete('sort')
		} else {
			// do not allow multi-column sorting
			// sorting format is ?sort=field.order
			params.set(
				'sort',
				`${newSortByField}.${newSortByOrder === true ? 'desc' : 'asc'}`,
			)
		}

		setSearchParams(params)
	}

	useEffect(() => {
		if (!initialField || !initialOrder) return
		setSorting([{ id: initialField, desc: initialOrder === 'desc' }])
	}, [initialField, initialOrder])

	return {
		sorting,
		onSortingChange,
	}
}

export type DataTableRef<TData> = {
	table: TanstackTable<TData>
}

type DataTableProps<TData> = {
	data: TData[]
	totalRows: number
	pageSize: number
	columns: ColumnDef<TData>[]
	cellStyleProps?: string
	paginationSettings?: PaginationSettings
	hideHeader?: boolean
	className?: string
	tableClassName?: string
	tableBodyClassName?: string
	tableRowClassName?: string
	sortProps?: useSortingProps

	// override the default pagination
	pagination?: PaginationState
	onPaginationChange?: (
		updaterOrValue: Updater<{
			pageIndex: number
			pageSize: number
		}>,
	) => void
}

export const DataTable = forwardRef(DataTableInner) as <TData>(
	props: DataTableProps<TData> & RefAttributes<DataTableRef<TData>>,
) => ReactElement

// export function DataTable<T>(props: DataTableProps<T>) {
function DataTableInner<TData>(
	props: DataTableProps<TData>,
	ref: React.ForwardedRef<DataTableRef<TData>>,
): ReactElement {
	// destructure the props
	const {
		data,
		totalRows,
		pageSize,
		columns,
		cellStyleProps,
		hideHeader = false,
		paginationSettings,
		className,
		tableClassName,
		tableBodyClassName,
		tableRowClassName,
		sortProps,
	} = props
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
		{},
	)
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
	const { onPaginationChange, pagination } = usePagination(pageSize)
	const { sorting, onSortingChange } = useSorting(sortProps)
	const [searchParams, setSearchParams] = useSearchParams()

	const pageCount = Math.ceil(totalRows / pageSize)

	const table = useReactTable<TData>({
		data,
		columns,
		onSortingChange,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		onPaginationChange: props.onPaginationChange ?? onPaginationChange,
		manualPagination: true,
		manualSorting: true,
		manualFiltering: true,
		enableSortingRemoval: false,
		enableMultiSort: false,
		enableRowSelection: true,
		enableMultiRowSelection: true,
		pageCount,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pagination: props.pagination ?? pagination,
		},
	})

	useImperativeHandle(
		ref,
		() => ({
			table,
		}),
		[table],
	)

	const startRow = pagination.pageIndex * pagination.pageSize + 1
	const endRow = Math.min(
		(pagination.pageIndex + 1) * pagination.pageSize,
		totalRows,
	)
	const numberFormatter = new Intl.NumberFormat()

	return (
		<div
			className={cn(
				'flex h-full w-full flex-col overflow-hidden bg-white',
				className,
			)}
		>
			<Table className={tableClassName}>
				{hideHeader ? null : (
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									// hide the header if accessorKey/Fn is not defined
									if (
										!header.column.accessorFn &&
										!headerGroup.id
									)
										return null
									const canSort = header.column.getCanSort()
									const isSorted = header.column.getIsSorted()

									return (
										<TableHead
											key={header.id}
											className={cn(
												'font-extralight',
												header.column.getCanSort()
													? 'cursor-pointer'
													: '',
											)}
											onClick={
												header.column.columnDef
													.enableSorting
													? header.column.getToggleSortingHandler()
													: undefined
											}
										>
											<div className="flex items-center justify-between gap-x-1">
												{header.isPlaceholder
													? null
													: flexRender(
															header.column
																.columnDef
																.header,
															header.getContext(),
														)}
												{canSort && (
													<>
														{isSorted === 'asc' && (
															<PiSortAscending className="flex h-5 w-5 flex-none" />
														)}
														{isSorted ===
															'desc' && (
															<PiSortDescending className="flex h-5 w-5 flex-none" />
														)}
														{isSorted === false && (
															<PiArrowsVerticalFill className="flex h-4 w-4 flex-none opacity-60" />
														)}
													</>
												)}
											</div>
										</TableHead>
									)
								})}
							</TableRow>
						))}
					</TableHeader>
				)}
				<TableBody className={tableBodyClassName}>
					{table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow
								key={row.id}
								data-state={row.getIsSelected() && 'selected'}
								className={tableRowClassName}
							>
								{row.getVisibleCells().map((cell) => (
									<TableCell
										key={cell.id}
										className={cn(cellStyleProps || '')}
									>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext(),
										)}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={columns.length}
								className="h-24 text-center"
							>
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
			{paginationSettings?.disabled ? null : (
				<div className="flex items-center justify-end space-x-2 border-t bg-background p-3">
					<div className="flex-1 text-sm text-muted-foreground/80">
						{`Showing `}
						<span>{numberFormatter.format(startRow)}</span>
						{` - `}
						<span>{numberFormatter.format(endRow)}</span>
						{` of `}
						<span>{numberFormatter.format(totalRows)}</span>
					</div>
					<div className="flex flex-row items-center text-sm text-muted-foreground/80">
						<span>Showing</span>
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<div className="cursor-pointer px-2">
									<Badge className="gap-x-0.5 py-1 pl-2 pr-1">
										<span>{pagination.pageSize}</span>
										<BiCaretDown className="h-4 w-4" />
									</Badge>
								</div>
							</DropdownMenuTrigger>
							<DropdownMenuContent>
								{PAGE_SIZE_OPTIONS.filter(
									(size) => size !== pagination.pageSize,
								).map((p) => (
									<DropdownMenuItem
										key={p}
										onClick={() => {
											const params = new URLSearchParams(
												searchParams,
											)
											params.set('limit', p.toString())
											setSearchParams(params)
										}}
									>
										{p}
									</DropdownMenuItem>
								))}
							</DropdownMenuContent>
						</DropdownMenu>

						<span>per page</span>
					</div>
					<div className="flex gap-x-4">
						<Pagination table={table} {...paginationSettings} />
					</div>
				</div>
			)}
		</div>
	)
}
